import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logoSendola from "@/assets/Img/logo-sendola.png";
import youtubeLogo from "@/assets/Img/youtube.png";
import facebookLogo from "@/assets/Img/facebook.png";
import instagramLogo from "@/assets/Img/instagram.png";
import linkedinLogo from "@/assets/Img/linkedin.png";
import TikTokLogo from "@/assets/Icons/tiktok-logo.svg?react";

export const Footer = () => {
  const [t] = useTranslation("global");

  return (
    <div className="w-full flex flex-col gap-6 bg-black px-6 lg:px-10 py-4 lg:pb-12">
      <div className="w-full flex gap-2 justify-between items-center">
        <img
          className="w-28 h-14 object-contain"
          src={logoSendola}
          alt="Sendola's logo"
        />

        <div className="flex gap-4">
          <a
            href="https://www.youtube.com/@Sendola-marketing"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-6 h-6 object-contain cursor-pointer"
              src={youtubeLogo}
              alt="Sendola's Youtube"
            />
          </a>
          <a
            href="https://www.tiktok.com/@sendola.io"
            target="_blank"
            rel="noreferrer"
          >
            <TikTokLogo className="w-6 h-6 object-contain cursor-pointer text-white" />
          </a>
          <a
            href="https://www.facebook.com/sendola.payments"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-6 h-6 object-contain cursor-pointer"
              src={facebookLogo}
              alt="Sendola's Facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/sendola.io/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-6 h-6 object-contain cursor-pointer"
              src={instagramLogo}
              alt="Sendola's Instagram"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/sendola-payments/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-6 h-6 object-contain cursor-pointer"
              src={linkedinLogo}
              alt="Sendola's LinkedIn"
            />
          </a>
        </div>
      </div>
      <div className="w-full flex gap-2 justify-between">
        <div className="text-xs md:text-sm lg:text-base text-gray-300">
          <p>{t("Footer.Address1")}</p>
          <p>{t("Footer.Address2")}</p>
          <p>{t("Footer.Address3")}</p>
          <p className="mt-6">{t("Footer.Phone")} +1 (281)-638-8018</p>
        </div>

        <div className="flex flex-col gap-2 items-end">
          <Link
            to="/legal"
            className="text-xs md:text-sm lg:text-base text-gray-300 underline"
          >
            {t("Footer.LegalInformation")}
          </Link>
          <Link
            to="/atm-network"
            className="text-xs md:text-sm lg:text-base text-gray-300 underline"
          >
            {t("Footer.AtmNetwork")}
          </Link>
          <a
            className="text-xs md:text-sm lg:text-base text-gray-300 underline"
            href="/why-sendola"
          >
            {t("Footer.About")}
          </a>
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <p
          className="text-[10px] md:text-xs lg:text-sm text-gray-300"
          dangerouslySetInnerHTML={{ __html: t("Footer.Disclaimer") }}
        />
      </div>
    </div>
  );
};
